import { nextTick } from "@vue/composition-api";


export const prepareAndPrint = async () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Wait for Vue to finish rendering
  await nextTick();

  const printArea = document.getElementById('printArea');

  if (!printArea || !printArea.innerHTML.trim()) {
    console.error("Print area is empty or not found!");
    return;
  }

  // Ensure images are loaded before printing
  checkImagesLoaded(printArea, isMobile);
};

const checkImagesLoaded = (printArea, isMobile) => {
  const images = printArea.querySelectorAll('img');
  let isAllLoaded = true

  images.forEach(element => {
    if (!element.complete) {
      isAllLoaded = false
    }
  })

  if (isAllLoaded) {
    console.log('is All Load is True')
    startPrint(isMobile, printArea.innerHTML);
  } else {
    console.log("is All Load is False")
    setTimeout(function () {
      checkImagesLoaded(printArea, isMobile)
    }, 200)
  }

};

const startPrint = (isMobile, printContent) => {
  setTimeout(() => {
    console.log('start Print called')
	printUsingPopup(printContent);
	/*
    if (isMobile) {
      printUsingIframe(printContent);
    } else {
      printUsingPopup(printContent);
    }
	*/
  }, 500);
};

// 🛠 Reliable Popup-based Printing for Desktop
const printUsingPopup = (printContent) => {
  console.log('print with popup')
  const printWindow = window.open('', '_blank', '');

  if (!printWindow) {
    console.error("Popup blocked! Enable popups for this site.");
    return;
  }

  printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <link rel="stylesheet" type="text/css" href="/styles/print.css">
      </head>
      <body>${printContent}</body>
    </html>
  `);

  printWindow.document.close();

  // Ensure print dialog opens only after the content is fully loaded
  printWindow.onload = () => {
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  setTimeout(() => {
    if (!printWindow.closed) {
      printWindow.print();
      printWindow.close();
    }
  }, 3000);
};

// 🛠 Reliable IFRAME Printing for Mobile
const printUsingIframe = (printContent) => {
  const iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.border = 'none';
  document.body.appendChild(iframe);

  const doc = iframe.contentWindow.document;
  doc.open();
  doc.write(`
    <html>
      <head>
        <title>Print</title>
        <link rel="stylesheet" type="text/css" href="/styles/print.css">
      </head>
      <body>${printContent}</body>
    </html>
  `);
  doc.close();

  iframe.onload = () => {
    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 500);
  };

  // Fallback: Try printing again if the first attempt fails
  setTimeout(() => {
    if (document.body.contains(iframe)) {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }
  }, 3000);
};

<template>
    <div>
        <h2>จัดซื้อ > รับของเข้า</h2>
        <order-status-bar
          :labelGroups="labelGroups"
          v-model="datatable.selectedStatus"
        />
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col>
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.ROG)"
                >
                  <v-list-item-title>ใบสั่งของ</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'rog_create', params: { rog_id: 'create' } })">สร้างรายการรับ</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/procure/rog/list'"
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template
            v-slot:item.orderNumber="{ item }"
          >
            <router-link
            :to="{ name: 'rog_create', params: { rog_id: item.id } }"
            >{{ item.orderNumber }}</router-link>
          </template>

          <template
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}
          </template>

          <template
            v-slot:item.supplier="{ item }"
          >
            {{ !!item.supplier ? item.supplier.name : '-' }}
          </template>

          <template
            v-slot:item.warehouse="{ item }"
          >
            {{ !!item.warehouse ? item.warehouse.name : '-' }}
          </template>

          <template
            v-slot:item.creator="{ item }"
          >
            {{ !!item.creator ? item.creator.name + ' ' + item.creator.lastname : '-' }}
          </template>

          <template
            v-slot:item.status="{ item }"
          >
            <v-chip
              :color="item.status | orderStatusToColor"
              label
            >
              {{ item.status | orderStatusToText }}
            </v-chip>
          </template>

          <template
            v-slot:item.actions="{ item }"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>{{ mdiMenu }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="item.status == 'new'" @click="acceptPO(item.id, item.viewToken, item.orderNumber)">
                  <v-list-item-icon>
                    <v-icon>{{mdiLinkVariant}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">รับของ</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item v-if="item.status != 'draft'" @click="printSingle($PRINT_TYPE.ROG, item.id)">
                  <v-list-item-icon>
                    <v-icon>{{mdiPrinter}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ใบรับของ</v-list-item-title>
                </v-list-item>

                <v-divider v-if="item.status != 'draft'"></v-divider>

                <v-list-item @click="$router.push({name: 'rog_create', params: { rog_id: item.id }})">
                  <v-list-item-icon>
                    <v-icon>
                      {{ $store.getters['auth/GET_ABILITY'].can('update', 'BuyOrder') ? mdiPlaylistEdit : mdiEyeOutline }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ดู / แก้ไข</v-list-item-title>
                </v-list-item>

                <template v-if="$store.getters['auth/GET_ABILITY'].can('update', 'BuyOrder')">
                  <v-divider></v-divider>

                  <v-list-item @click="cancelOrder(item.id, item.orderNumber)">
                    <v-list-item-icon>
                      <v-icon>
                      {{ mdiDelete }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ยกเลิก</v-list-item-title>
                  </v-list-item>
                </template>

              </v-list>
            </v-menu>
          </template>
          </delay-datatable>
        </v-card>
    </div>
</template>
<script>
import { prepareAndPrint } from '@/helpers/printing.js'
import { ROGOrder } from '@/classes/order/ROGOrder'
import { ROGOrderItem } from '@/classes/order/ROGOrderItem'
import OrderStatusBar from '@/components/OrderStatusBar.vue'
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete, asyncGet } from '@/helpers/asyncAxios'
import {
  mdiCashCheck,
  mdiCheck,
  mdiCloudUpload,
  mdiDelete,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
        orderNumber: null,
      },
      labelGroups: [
        [
          {
            id: 'all',
            text: 'ทั้งหมด',
            counter: 0,
            color: '#D64935',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'new',
            text: 'ใหม่',
            counter: 0,
            color: '#F3CC63',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'received',
            text: 'รับแล้ว',
            counter: 0,
            color: '#6CCCA3',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'cancelled',
            text: 'ยกเลิก',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
        ],
      ],
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'เลขที่บิล',
            value: 'orderNumber',
          },
          {
            text: 'วันที่สร้าง',
            value: 'created_at',
          },
          {
            text: 'คลัง/สาขา',
            value: 'warehouse',
            sortable: false,
          },
          {
            text: 'ชื่อผู้จำหน่าย',
            value: 'supplier',
            sortable: false,
          },
          {
            text: 'มูลค่ารวม',
            value: 'value',
          },
          {
            text: 'สถานะ',
            value: 'status',
          },
          {
            text: 'ผู้สร้าง',
            value: 'creator',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        selectedStatus: this.datatable.selectedStatus,
        search: this.datatable.search,
      }
    },
  },
  created() {
    this.queryCount()
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async queryCount() {
      try {
        const { total, draft, new_, received, cancelled } = await asyncGet('/procure/rog/count')

        this.labelGroups = [
          [
            {
              id: 'all',
              text: 'ทั้งหมด',
              counter: total,
              color: '#D64935',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'received',
              text: 'รับแล้ว',
              counter: received,
              color: '#6CCCA3',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'cancelled',
              text: 'ยกเลิก',
              counter: cancelled,
              color: '#5B6573',
              textColor: 'white',
            },
          ],
        ]
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async acceptPO(id) {
      // TODO : GO TO ROG
    },
    async cancelOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยกเลิก ?',
        body:
          'คุณต้องการยกเลิกบิลหมายเลข "' +
          orderNumber +
          '" ใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      this.sendingData = true
      try {
        await asyncDelete('/procure/rog/delete', {
          items: [id],
        })

        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.sendingData = false
    },
    async printMultiple($type) {
      const itemIds = this.datatable.selectedItems.map(item => item.id)

      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      try {
        const { company, data } = await asyncGet('/procure/rog/' + $type, { items: itemIds })

        const orders = data.map(item => {
          const order = new ROGOrder(item)
          const { products } = item

          products.forEach(element => {
            const orderItem = new ROGOrderItem({
              id: element.id,
              images: element.images,
              name: element.name,
              sku: element.sku,
              barCode: element.barCode,
              note: element.pivot.note,
              quantity: element.pivot.quantity,
              price: element.pivot.price,
              discount: element.pivot.discount,
              discount_type: element.pivot.discount_type,
              vat: element.pivot.vat,
              unit: element.unit,
            })

            order.addOrderItem(orderItem)
          })

          return order
        })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: orders,
          company: company,
        })

        prepareAndPrint()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printSingle($type, id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const { company, data } = await asyncGet('/procure/rog/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => {
            const order = new ROGOrder(item)
            const { products } = item

            products.forEach(element => {
              const po = !!item.po ? item.po : null
              let expectedQuantity = 0

              if (po) {
                const expectedProduct = po.products.find(poItem => poItem.id == element.id)

                if (expectedProduct) expectedQuantity = expectedProduct.pivot.quantity
              }

              const orderItem = new ROGOrderItem({
                id: element.id,
                images: element.images,
                name: element.name,
                sku: element.sku,
                barCode: element.barCode,
                note: element.pivot.note,
                quantity: element.pivot.quantity,
                expectedQuantity: expectedQuantity,
                price: element.pivot.price,
                discount: element.pivot.discount,
                discount_type: element.pivot.discount_type,
                vat: element.pivot.vat,
                unit: element.unit,
              })

              order.addOrderItem(orderItem)
            })

            return order
          }),
          company: company,
        })

        prepareAndPrint()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  components: {
    ProductImage,
    OrderStatusBar,
  },
}
</script>
